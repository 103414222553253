<template>
  <div />
</template>

<script>
import all from './all';

export default {
  name: 'AllPositionings',

  mounted() {
    this.$el.append(all());
  },
};
</script>

<style></style>
