import { ap, cr } from '@/lib/ui/dom';
import { translate as t } from '@/util/i18n';
import api from '@/util/api';
import emptyState from '@/lib/ui/emptyState';
import repeat from '@/lib/util/repeat';
import pager from '@/lib/ui/pager';
import errorMessage from '@/util/errorMessage';
import moment from 'moment';
import { profilePhotoSrc } from '@/lib/ui/profilePhoto';

const mine = () => {
  const list = cr('ol', 'positioning-list');

  const createListItem = (positioning) => {
    const date = moment(positioning.sentTs);

    return ap(
      cr('li'),
      ap(
        cr('label', 'user'),
        t('positionedBy') + ': ',
        profilePhotoSrc(positioning.userName, positioning.profilePhoto),
        positioning.userName,
      ),
      cr('label', 'date', date.format('DD.MM.YYYY HH:mm')),
      cr('label', 'reason', t('reason') + ': ' + positioning.reason),
    );
  };

  const reloadPositionings = (page, pageSize) => {
    api
      .getUserPositionings({
        page,
        pageSize,
      })
      .then((res) => {
        const positionings = res.positionings;
        const pageCount = res.pageCount;

        listPager.update(pageCount, page);

        list.innerHTML = '';
        if (positionings.length < 1 && pageCount < 2) {
          ap(
            list,
            emptyState(
              t('noPositionings'),
              t('noPositioningsMsg'),
              '/static/images/empty-states/positioning.svg',
            ),
          );
        } else {
          ap(
            list,
            positionings.map((positioning) => createListItem(positioning)),
          );
        }
      })
      .catch((res) =>
        errorMessage(t('getUserPositioningsError'), t('getUserPositioningsErrorMsg'), res),
      );
  };

  let page = 1;
  const pageSize = 10;

  const listPager = pager({
    selectPage: (newpage) => {
      page = newpage;
      reloadPositionings(page, pageSize);
    },
    page,
    previousTxt: t('previous'),
    nextTxt: t('next'),
  });

  reloadPositionings(page, pageSize);
  repeat.ifBodyContains(() => reloadPositionings(page, pageSize), list, 10000);

  return ap(
    cr('section', 'c-positionings mine'),
    list,
    ap(cr('div', 'c-level'), listPager.element),
  );
};

export default mine;
