<template>
  <div />
</template>

<script>
import mine from './mine';

export default {
  name: 'MyPositionings',

  mounted() {
    this.$el.append(mine());
  },
};
</script>

<style></style>
