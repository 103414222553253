import { ap, cr } from './dom';
import button from './button';
import { hide, show } from './showAndHide';

/**
 * Creates a pager.
 * @param {object} config
 * @param {number} config.page
 * @param {function(int):void} config.selectPage
 * @param {string} [config.previousTxt]
 * @param {string} [config.nextTxt]
 */
const pager = (config) => {
  let page = config.page;
  let selectPage = config.selectPage;

  const previous = () => {
    selectPage(page - 1);
  };

  const prevBtn = button(previous, null, config.previousTxt || 'Previous');

  const next = () => {
    selectPage(page + 1);
  };

  const nextBtn = button(next, null, config.nextTxt || 'Next');

  const pageButtons = cr('ul', 'page-buttons');

  const element = ap(cr('div', 'c-pager'), prevBtn, pageButtons, nextBtn);

  const update = (pageCount, currentPage) => {
    if (pageCount < 2) {
      hide(element);
      return;
    } else {
      show(element);
    }

    page = currentPage;

    prevBtn.disabled = currentPage <= 1 || pageCount < 2;
    nextBtn.disabled = currentPage >= pageCount || pageCount < 2;

    pageButtons.innerHTML = '';

    for (let idx = 1; idx <= pageCount; idx++) {
      const className = idx === currentPage ? 'current' : null;

      const display =
        idx === 1 || // First
        idx === currentPage ||
        (currentPage <= 4 && idx <= 6) ||
        (currentPage > pageCount - 4 && idx > pageCount - 6) ||
        Math.abs(idx - currentPage) < 3 ||
        idx === pageCount; // Last

      if (display) {
        ap(
          pageButtons,
          button(() => selectPage(idx), className, idx),
        );
      }
    }
  };

  return {
    element,
    update,
  };
};

export default pager;
