<template>
  <div>
    <v-tabs v-model="activeTab">
      <template v-for="(tab, index) in tabs">
        <v-tab :key="index">{{ tab.title }}</v-tab>
      </template>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <my-positionings />
        </v-tab-item>
        <v-tab-item>
          <all-positionings />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { translate as t } from '@/util/i18n';
import MyPositionings from './MyPositionings';
import AllPositionings from './AllPositionings';

export default {
  name: 'Positionings',

  components: {
    MyPositionings,
    AllPositionings,
  },

  data: () => ({
    activeTab: null,

    tabs: [
      {
        title: t('mine'),
        path: '/positionings/mine',
      },
      {
        title: t('all'),
        path: '/positionings/all',
      },
    ],
  }),
};
</script>

<style></style>
